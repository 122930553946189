/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~bootstrap/dist/css/bootstrap.min.css";

/* Syncfusion */
//@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

html,
body {
  background-color: #fff;
  color: #5e5e5e;
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.btnalert {
  background-color: var(--ion-color-danger) !important;
  color: white !important;
}

.infoIcon {
  font-size: 130%;
  padding-right: 10px;
  cursor: pointer;
}

.help-text {
  font-size: 80%;
}

.help-popover {
  --min-width: 320px;
  //--background: var(--ion-color-danger);
}

.box-top {
  margin-left: 0;
}

.box-card {
  box-shadow: 0 0 15px -6px grey;
  border: 1px solid grey;
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box-title {
    padding: 10px 15px;
  }
}

.box-listbuttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.box-inner-header {
  font-size: 50%;
  font-weight: bold;

  .box-listname {
    text-align: left !important;
  }

  ion-col {
    div {
      width: 100%;
      text-align: left;
    }
  }
}

.listlabel {
  min-width: 30%;
}

.sub-listlabel {
  min-width: 50%;
}

.list-item:hover {
  background-color: #cacacb;
  cursor: pointer;
}

.doclink {
  color: #007bff;
  cursor: pointer;
}

.doclink:hover {
  color: #0056b3;
  text-decoration: underline;
}

.list-stepitem {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-pad-10 {
  padding-left: 10px;
  text-align: left;
  //white-space: pre-wrap;
}

.list-stepheader {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-right: 15px;
}

.list-stepstitle {
  padding: 5px 0 5px 10px;
  margin: 0 5px;
  height: 100%;
  width: 100%;
  justify-self: flex-start;
  background-color: var(--ion-color-secondary);
  text-align: left;
}

.list-stepbuttons {
  position: relative;
  left: 10px;
  margin: 0 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  height: 40px;

  ion-icon {
    height: 50%;
  }

  ion-icon:hover {
    background-color: #d7d7d7;
  }

}

.right-pad-15 {
  padding-right: 15px;
}

.pencil,
.trash {
  justify-self: flex-end;
  align-self: stretch;
  padding: 5px 5px;
  flex-grow: 0;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 20%;
  margin-left: 2px;
}

.pencil:hover,
.trash:hover {
  background-color: #e8e8e8;
}

.filterList .alert-wrapper {
  min-width: 55vw;
}

.filterUser .alert-wrapper {
  min-width: 40vw;
}

ion-modal.wideModal::part(content) {
  --height: 80%;
  --width: 90%;
}

ion-modal.modalBorder::part(content) {
  border: 1px solid black;
  box-shadow: 0 0 15px -6px grey;
}

.catplus {
  vertical-align: super;
  font-size: 70%;
}

.penciling {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.trashing {
  color: var(--ion-color-danger);
  cursor: pointer;
}

.invalid {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

.redAlert {
  border: 1px solid #e8477f;
  background-color: rgba(213, 144, 164, 0.64);
}

:root {
  --ion-color-pink: #ec09e5;
  --ion-color-pink-rgb: 236, 9, 229;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255, 255, 255;
  --ion-color-pink-shade: #d008ca;
  --ion-color-pink-tint: #ee22e8;

  --ion-color-greenzone: #00b150;
  --ion-color-greenzone-rgb: 0, 177, 80;
  --ion-color-greenzone-contrast: #ffffff;
  --ion-color-greenzone-contrast-rgb: 255, 255, 255;
  --ion-color-greenzone-shade: #009c46;
  --ion-color-greenzone-tint: #1ab962;

  --ion-color-redzone: #ff0000;
  --ion-color-redzone-rgb: 255, 0, 0;
  --ion-color-redzone-contrast: #ffffff;
  --ion-color-redzone-contrast-rgb: 255, 255, 255;
  --ion-color-redzone-shade: #e00000;
  --ion-color-redzone-tint: #ff1a1a;

  --ion-color-lagerzone: #77d4ff;
  --ion-color-lagerzone-rgb: 119, 212, 255;
  --ion-color-lagerzone-contrast: #000000;
  --ion-color-lagerzone-contrast-rgb: 0, 0, 0;
  --ion-color-lagerzone-shade: #69bbe0;
  --ion-color-lagerzone-tint: #85d8ff;
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-greenzone {
  --ion-color-base: var(--ion-color-greenzone);
  --ion-color-base-rgb: var(--ion-color-greenzone-rgb);
  --ion-color-contrast: var(--ion-color-greenzone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greenzone-contrast-rgb);
  --ion-color-shade: var(--ion-color-greenzone-shade);
  --ion-color-tint: var(--ion-color-greenzone-tint);
}

.ion-color-redzone {
  --ion-color-base: var(--ion-color-redzone);
  --ion-color-base-rgb: var(--ion-color-redzone-rgb);
  --ion-color-contrast: var(--ion-color-redzone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-redzone-contrast-rgb);
  --ion-color-shade: var(--ion-color-redzone-shade);
  --ion-color-tint: var(--ion-color-redzone-tint);
}

.ion-color-lagerzone {
  --ion-color-base: var(--ion-color-lagerzone);
  --ion-color-base-rgb: var(--ion-color-lagerzone-rgb);
  --ion-color-contrast: var(--ion-color-lagerzone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lagerzone-contrast-rgb);
  --ion-color-shade: var(--ion-color-lagerzone-shade);
  --ion-color-tint: var(--ion-color-lagerzone-tint);
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

h2 {
  padding-top: 60px;
  padding-bottom: 20px;
  font-size: 120%;
}

.markdown {
  h1 {
    font-size: 170%;
    font-weight: bold;
    padding: 30px 0 5px 0;
    margin: 0 0;
  }

  h2 {
    font-size: 140%;
    font-weight: bold;
    padding: 20px 0 5px 0;
    margin: 0 0;
  }

  h3 {
    font-weight: bold;
    font-size: 120%;
    padding: 10px 0 5px 0;
    margin: 0 0;
  }

  h4, h5, h6 {
    font-weight: bold;
    font-size: 110%;
    padding: 10px 0 5px 5px;
    margin: 0 0;
  }

  p {
    padding: 5px 0 0 0;
    margin: 0 0;
  }

  blockquote {
    background-color: #dcdcde;
    border-left: 5px solid #8d8d8d;
    padding: 5px 10px;
    margin: 20px 20px;
  }
}

markdown {
  p {
    margin: 0 0 2px 0;
  }

  ul {
    margin: 0 0 5px 0;
  }

}

.sc-ion-alert-md {
  white-space: normal !important;
}

.fullModal {
  &::part(content) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.minicon {
  align-self: center;
  border: 1px black solid;
  border-radius: 20%;
  padding: 2px 2px;
  margin-left: 3px;
  cursor: pointer;
}
.minicon:hover {
  background: var(--ion-color-light);
}

.redicon {
  border: 1px var(--ion-color-danger) solid;
  color: var(--ion-color-danger);
}

.qssIcon {
  margin: 12px 5px;
  padding-left: 3px;
  //border: 1px black solid;
  //border-radius: 50%;
  font-family: "Arial", serif;
  letter-spacing: -1px;
  font-size: 70%;
  color: black;
}

.mainContent {
  display: flex;
}